





























































import Vue from "vue";

import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";

export default Vue.extend({
  name: "Calendar",
  beforeMount() {
    this.$store.dispatch("directorio/GetCalendar");
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  data: () => ({
    mdiChevronRight,
    mdiChevronLeft,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    // weekdays: [
    //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
    //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
    //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
    //   { text: "Mon, Wed, Fri", value: [1, 3, 5] }
    // ],
    value: "",
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  computed: {
    events() {
      const events = this.$store.getters["directorio/calendarEvents"];
      return events.map((e) => ({
        id: e.id,
        name: e.name,
        start: e.startDate,
        end: e.endDate,
        color: this.colors[this.rnd(0, this.colors.length - 1)],
      }));
    },
  },
  methods: {
    setToday() {
      this.value = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    showEvent({ event }) {
      this.$router.push({ name: "Event", params: { id: event.id } });
    },
  },
  watch: {
    events() {
      this.$refs.calendar.checkChange();
    },
  },
});
